import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { observer, Observer } from "mobx-react-lite";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import { useAppState } from "../../states/app-state";
import LogoutButton from "../login/LogoutButton";
import { log } from "../../components/error/error";
import WhatsAppFeedBack from "../../components/common/WhatsAppFeedBack";

const IdVerification: React.FC = observer(() => {
  const appState = useAppState();
  const { idVerification } = appState.registration;
  const [getNccLinkLoading, setGetNccLinkLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const nextHandler = async () => {
    window.scrollTo(0, 0);
    try {
      setLoading(true);
      await idVerification.onboardingNccNextButton();
      setLoading(false);
    } catch (err: any) {
      log(err);
      setLoading(false);
    }
  };

  const getNccLink = async () => {
    try {
      setGetNccLinkLoading(true);
      await idVerification.onboardingGetNccLink();
      setGetNccLinkLoading(false);
    } catch (err: any) {
      log(err);
      setGetNccLinkLoading(false);
    }
  };
  const changeHandler = () => {
    idVerification.setChecked(!idVerification.checked);
    idVerification.setShowNccBtn(true);
  };

  React.useEffect(() => {
    idVerification.responseOfNCC();
    window.open(idVerification.url, "_parent");
  });

  React.useEffect(() => {
    appState.getOnboardingRemoteConfigParameters();
  }, [appState]);


  return (
    <>
      {appState.onboardingRemoteConfigParameters && <WhatsAppFeedBack />}
      <div style={{ marginBottom: "10px" }}>
        <LogoutButton />
      </div>
      <Typography>
        Bearer partners with “National Crime Check Pty Ltd” for an easy and
        secure online solution for Identity Verification, Right to Work in
        Australia and Criminal History Check.
        <br />
        Providing false or inaccurate details might cause delays or even
        unsuccessful check results. By either of having unsuccessful check
        outcomes or incomplete checks, you will not be able to complete the
        onboarding process as a Bearer service provider and delays for assessing
        your application could be expected.
      </Typography>
      <FormGroup sx={{ marginTop: "20px" }}>
        <Observer>
          {() => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={idVerification.checked}
                    onChange={changeHandler}
                    disabled={idVerification.checked ? true : false}
                  />
                }
                label={
                  <>
                    <Typography>
                      I hereby give the permission to Bearer to:
                    </Typography>
                    <Typography>
                      a. Send my basic personal information to NCC™ and InstaID™
                      for ID Verification, Right-to Work and Criminal History
                      Check purposes.
                    </Typography>
                    <Typography>
                      b. Run an assessment to decide about my eligibility to
                      work as ‘Bearer Service Provider’ based on the feedback
                      provided by Bearer’s legal partners.
                    </Typography>
                  </>
                }
              />
            );
          }}
        </Observer>
      </FormGroup>

      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Observer>
            {() => {
              return (
                <Typography sx={{ textAlign: "justify" }}>
                  {idVerification.message}
                </Typography>
              );
            }}
          </Observer>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Observer>
            {() => {
              return (
                <>
                  <Button
                    sx={{ backgroundColor: "#0D47A1", color: "#fff" }}
                    variant="contained"
                    onClick={getNccLink}
                    disabled={!idVerification.showNccBtn}
                  >
                    Open NCC
                  </Button>
                  {getNccLinkLoading && <DisabledClickLoading />}
                </>
              );
            }}
          </Observer>
        </Grid>
        <Grid item xs={12} textAlign="center" sx={{ marginTop: 3 }}>
          <Observer>
            {() => {
              const valid = idVerification.nextBtn;
              return (
                <>
                  <Button
                    variant="contained"
                    disabled={!valid}
                    onClick={nextHandler}
                  >
                    Next
                  </Button>
                  {loading && <DisabledClickLoading />}
                </>
              );
            }}
          </Observer>
        </Grid>
      </Grid>
    </>
  );
});
export default IdVerification;
