import { CssBaseline, ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import Scaffold from "./containers/Scaffold";
import { AppStateProvider } from "./states/app-state";
import theme from "./theme";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";

function App() {
  const [isIpAddress, setIsAddress] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const getIp = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      if (!response.ok) {
        window.localStorage.removeItem("ip");
        setDialogOpen(true);
        setIsAddress(false);
      }
      const data = await response.json();
      setIsAddress(true);
      window.localStorage.setItem("ip", data.ip);
    } catch (err) {
      window.localStorage.removeItem("ip");
      setDialogOpen(true);
      setIsAddress(false);
    }
  };

  useEffect(() => {
    getIp();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isIpAddress && (
          <AppStateProvider>
            <Scaffold />
          </AppStateProvider>
        )}
      </ThemeProvider>
      <Dialog open={dialogOpen}>
        <DialogContent>
          <DialogContentText>
            For legal purposes, during the registration, we need to have access
            to your IP address. Please turn off anti-virus software and try
            again.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default App;
