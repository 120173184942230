import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendSignInLinkToEmail,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  updatePassword,
  reauthenticateWithCredential,
} from "firebase/auth";
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from "firebase/remote-config";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  ref,
  getDownloadURL,
  getStorage,
  uploadBytesResumable,
} from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { error } from "../components/error/error";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_ApiKey,
  authDomain: process.env.REACT_APP_AuthDomain,
  databaseURL: process.env.REACT_APP_DatabaseURL,
  projectId: process.env.REACT_APP_ProjectId,
  storageBucket: process.env.REACT_APP_StorageBucket,
  messagingSenderId: process.env.REACT_APP_MessagingSenderId,
  appId: process.env.REACT_APP_AppId,
  measurementId: process.env.REACT_APP_MeasurementId,
};

const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
const auth = getAuth();
const remoteConfig = getRemoteConfig();
const storage = getStorage();

const actionCodeSettings = {
  url: "",
  handleCodeInApp: true,
};

export type TSignUpResponse = {
  uid: string;
  email: string;
};

export type NoCloudFunctionData = {
  text: string;
  buttonAction: string;
  url: string;
  supportUrl: string;
};

export type RemoteConfigBaseUrl = {
  rider_onboarding_url: string;
  bearer_website_base_url: string;
  environment_code: string;
};


export type NoOnboardingData = {
  text: string;
  buttonAction: string;
  url: string;
  supportUrl: string;
};
export type OnboardingRemoteConfigParameters = {
  stripeUrl: string;
  landingpageUrl: string;
  soleTraderUrl: string;
  rider_pp_url: string;
  rider_tc_url: string;
  whatsappLiveSupport: string;
};

export type TMotorbikeDetails = {
  engine_size?: string;
  ipc_insurer_company?: string;
  ipc_policy_number?: string;
  ipc_policy_type?: string;
  ipc_validity_date?: string | null;
  make?: string;
  model?: string;
  motorbike_type?: string;
  motorbike_uid?: string | null;
  registration_number?: string;
  registration_state?: string;
  rego_validity_date?: string | null;
  rego_certificate_url?: string;
  ipc_policy_certificate_url?: string;
};

export type TOnboardingLoginPermissionSuccess =
  | {
    status: "Successful";
    code: 200;
    message: string;
    data_model_version?: string;
    is_sign_up_completed?: boolean;
    is_user_allowed_by_bearer?: boolean;
    is_user_password_set?: boolean;
    pp_agreed?: boolean;
    tc_agreed?: boolean;
    latest_tc_pp_vers_sign_date?: {
      _nanoseconds: number;
      _seconds: number;
    };
    rider_registered_for_gst?: boolean;
    public_liability_insurance_not_provided?: boolean;
    sign_up_stage?: number;
    sign_up_sub_stage?: number;
    mb_deletes_during_onboarding?: number;
    email_address?: string;
    avatar_url?: string;
    id_photo_card_url: string;
    is_id_photo_card_uploaded: boolean;
    birth_date?: string;
    gender?: string;
    given_name?: string;
    last_name?: string;
    middle_name?: string;
    mobile_number?: string;
    mobile_number_is_verified?: true;
    mobile_number_token?: string;
    mobile_number_token_expiry_time?: number;
    residential_address_segmented?: {
      apt_no?: string;
      country?: string;
      post_code?: string;
      region_area?: string;
      state?: string;
      street_name?: string;
      street_no?: string;
      street_type?: string;
      unit_no?: string;
    };
    legal_details?: {
      abn?: string;
      legal_address_segmented?: {
        apt_no?: string;
        country?: string;
        post_code?: string;
        region_area?: string;
        state?: string;
        street_name?: string;
        street_no?: string;
        street_type?: string;
        unit_no?: string;
      };
      legal_name?: string;
      legal_type?: string;
      phone_number?: string;
    };
    public_liability_insurance?: {
      public_liability_insurance_type?: string;
      public_liability_insurer_company?: string;
      public_liability_insurance_number?: string;
      insurance_number?: string;
      insurance_type?: string;
      insurer_company?: string;
    };
    public_liability_insurance_validity_date?: string | null;
    motorbike?: TMotorbikeDetails;
    stripe?: {
      payouts_enable?: boolean | null;
      details_submitted?: boolean;
    };
    ncc?: {
      latest_result?: string | null;
      latest_status?: string | null;
      latest_verify_status?: string | null;
    };
    available_services?: {
      cycling?: boolean;
      cycling_last_update: {
        _nanoseconds?: number;
        _seconds?: number;
      };
    };
  }
  | {
    status: "Successful";
    code: 201;
    message: string;
  };
export type TUrl = {
  redirect_url: string;
};
export type TOnboardingLoginPermissionError =
  | {
    status: "Client Error" | "Server Error";
    code: 401 | 408 | 500;
    message: string;
  }
  | ({
    status: "Client Error";
    code: 416;
    message: string;
  } & TUrl);

export type TLoginResponse = {
  uid: string;
};
export type TOnboardingNotifySetPasswordRequest = {
  email_address: string;
  ip: string | null;
};
export type TOnboardingNotifySetPasswordSuccess = {
  status: "Successful";
  code: 200;
  message: string;
};
export type TOnboardingNotifySetPasswordError = {
  status: "Client Error" | "Server Error";
  code: 401 | 500;
  message: string;
};
export type TOnboardingSendSMSRequest = {
  mobile_number: string;
};
export type TOnboardingSendSMSSuccess = {
  status: "Successful";
  code: 200;
  message: string;
};
export type TOnboardingSendSMSError = {
  status: "Client Error" | "Server Error";
  code: 401 | 500;
  message: string;
};
export type TOnboardingConfirmSMSRequest = {
  token: string;
};
export type TOnboardingConfirmSMSSuccess = {
  status: "Successful";
  code: 200;
  message: string;
};
export type TOnboardingConfirmSMSError = {
  status: "Client Error" | "Server Error";
  code: 400 | 401 | 423 | 500;
  message: string;
};

export type TOnboardingPersonalDetailsRequest = {
  given_name: string;
  last_name: string;
  avatar_url: string;
  birth_date: string | null;
  gender: string;
  middle_name: string;
  id_photo_card_url: string;
  is_id_photo_card_uploaded: boolean;
  residential_address_segmented: {
    apt_no: string;
    unit_no: string;
    street_no: string;
    street_name: string;
    street_type: string;
    region_area: string;
    state: string;
    post_code: string;
    country: string;
  };
};
export type TOnboardingPersonalDetailsSuccess = {
  status: "Successful";
  code: 200;
  message: string;
};
export type TOnboardingPersonalDetailsError = {
  status: "Client Error" | "Server Error";
  code: 401 | 500 | 400;
  message: string;
};
export type TOnboardingLegalDetailsRequest = {
  legal_name: string;
  abn: string;
  phone_number: string;
  legal_type: string | null;
  rider_registered_for_gst: boolean;
  public_liability_insurance_not_provided: boolean;
  legal_address_segmented: {
    apt_no: string;
    unit_no: string;
    street_no: string;
    street_name: string;
    street_type: string;
    region_area: string;
    state: string;
    post_code: string;
    country: string;
  };
  public_liability_insurance_validity_date: string | null;
  public_liability_insurance_type: string;
  public_liability_insurer_company: string;
  public_liability_insurance_number: string;
  public_liability_insurance_certificate_url: string;
};
export type TOnboardingLegalDetailsSuccess = {
  status: "Successful";
  code: 200;
  message: string;
};
export type TOnboardingLegalDetailsError = {
  status: "Client Error" | "Server Error";
  code: 401 | 428 | 429 | 499 | 500;
  message: string;
};
export type TOnboardingAddMotorbikeRequest = {
  motorbike_type: string | null;
  make: string;
  model: string;
  engine_size: string;
  registration_number: string;
  registration_state: string;
  ipc_policy_type: string | null;
  ipc_insurer_company: string;
  ipc_policy_number: string;
  ipc_validity_date: string | null;
  ipc_policy_certificate_url: string;
  rego_validity_date: string | null;
  rego_certificate_url: string;
};
export type TOnboardingAddMotorbikeSuccess = {
  status: "Successful";
  code: 200;
  message: string;
  motorbike_id: string;
  ipc_policy_certificate_url: string;
  rego_certificate_url: string;
};
export type TOnboardingAddMotorbikeError = {
  status: "Client Error" | "Server Error";
  code: 401 | 409 | 417 | 419 | 500;
  message: string;
};
export type TOnboardingRemoveMotorbikeRequest = {
  motorbike_id: string;
};
export type TOnboardingRemoveMotorbikeSuccess = {
  status: "Successful";
  code: 200;
  message: string;
  mb_deletes_during_onboarding: number;
};
export type TOnboardingRemoveMotorbikeError = {
  status: "Client Error" | "Server Error";
  code: 401 | 409 | 500;
  message: string;
};
export type TOnboardingEditMotorbikeRequest = {
  motorbike_id: string | null;
  motorbike_type: string | null;
  make: string;
  model: string;
  engine_size: string;
  registration_number: string;
  registration_state: string;
  ipc_policy_type: string | null;
  ipc_insurer_company: string;
  ipc_policy_number: string;
  ipc_validity_date: string | null;
  ipc_policy_certificate_url: string;
  rego_validity_date: string | null;
  rego_certificate_url: string;
  is_ipc_policy_certificate_uploaded: boolean;
  is_rego_certificate_uploaded: boolean;
};
export type TOnboardingEditMotorbikeSuccess = {
  status: "Successful";
  code: 200;
  message: string;
};
export type TOnboardingEditMotorbikeError =
  | {
    status: "Client Error" | "Server Error";
    code: 401 | 500;
    message: string;
  }
  | {
    status: "Client Error";
    code: 409;
    message: string;
    make: string;
    model: string;
  };
export type TOnboardingModesOfTransportRequest = {
  cycling: boolean;
};
export type TOnboardingModesOfTransportSuccess = {
  status: "Successful";
  code: 200;
  message: string;
};
export type TOnboardingModesOfTransportError = {
  status: "Client Error" | "Server Error";
  code: 401 | 500;
  message: string;
};

export type TOnboardingStripeSuccess = {
  status: "Successful";
  code: 200;
  message: string;
  idv_and_contact_details_match: boolean;
  is_allow_id_verification_try: boolean;
};

export type TOnboardingStripeError = {
  status: "Client Error" | "Server Error";
  code: 401 | 500;
  message: string;
};

export type TOnboardingGetNccLinkSuccess = {
  status: "Successful";
  code: 200;
  message: string;
  continue_url: string;
};
export type TOnboardingGetNccLinkError = {
  status: "Client Error" | "Server Error";
  code: 400 | 401 | 500;
  message: string;
};
export type TOnboardingNccNextButtonSuccess = {
  status: "Successful";
  code: 200;
  message: string;
};
export type TOnboardingNccNextButtonError = {
  status: "Client Error" | "Server Error";
  code: 401 | 500;
  message: string;
};

export type TOnboardingDigitalSignatureRequest = {
  name_as_signed: string;
  referred_by: string;
  ip: string | null;
};
export type TOnboardingDigitalSignatureSuccess = {
  status: "Successful";
  code: 200;
  message: string;
};
export type TOnboardingDigitalSignatureError = {
  status: "Client Error" | "Server Error";
  code: 400 | 401 | 500;
  message: string;
};

export type TOnboardingGetStripeExpressLinkSuccess = {
  status: "Successful";
  code: 200;
  message: string;
  account_url: string;
};
export type TOnboardingGetStripeExpressLinkError = {
  status: "Client Error" | "Server Error";
  code: 401 | 500;
  message: string;
};

export type TOnboardingLoginPermissionResponse =
  | TOnboardingLoginPermissionSuccess
  | TOnboardingLoginPermissionError;
export type TOnboardingNotifySetPasswordResponse =
  | TOnboardingNotifySetPasswordSuccess
  | TOnboardingNotifySetPasswordError;
export type TOnboardingSendSMSResponse =
  | TOnboardingSendSMSSuccess
  | TOnboardingSendSMSError;
export type TOnboardingConfirmSMSResponse =
  | TOnboardingConfirmSMSSuccess
  | TOnboardingConfirmSMSError;
export type TOnboardingPersonalDetailsResponse =
  | TOnboardingPersonalDetailsSuccess
  | TOnboardingPersonalDetailsError;
export type TOnboardingLegalDetailsResponse =
  | TOnboardingLegalDetailsSuccess
  | TOnboardingLegalDetailsError;
export type TOnboardingAddMotorbikeResponse =
  | TOnboardingAddMotorbikeSuccess
  | TOnboardingAddMotorbikeError;
export type TOnboardingRemoveMotorbikeResponse =
  | TOnboardingRemoveMotorbikeSuccess
  | TOnboardingRemoveMotorbikeError;
export type TOnboardingEditMotorbikeResponse =
  | TOnboardingEditMotorbikeSuccess
  | TOnboardingEditMotorbikeError;
export type TOnboardingModesOfTransportResponse =
  | TOnboardingModesOfTransportSuccess
  | TOnboardingModesOfTransportError;
export type TOnboardingStripeResponse =
  | TOnboardingStripeSuccess
  | TOnboardingStripeError;
export type TOnboardingGetNccLinkResponse =
  | TOnboardingGetNccLinkSuccess
  | TOnboardingGetNccLinkError;
export type TOnboardingNccNextButtonResponse =
  | TOnboardingNccNextButtonSuccess
  | TOnboardingNccNextButtonError;
export type TOnboardingDigitalSignatureResponse =
  | TOnboardingDigitalSignatureSuccess
  | TOnboardingDigitalSignatureError;
export type TOnboardingGetStripeExpressLinkResponse =
  | TOnboardingGetStripeExpressLinkSuccess
  | TOnboardingGetStripeExpressLinkError;

export const fetchDownloadUrl = async (url: string): Promise<string> => {
  try {
    const downloadUrl = await getDownloadURL(ref(storage, url));
    return downloadUrl;
  } catch (err) {
    error(err);
    return url;
  }
};

export const BearerApi = {
  fetchRemoteConfigurations: async () => {
    try {
      await fetchAndActivate(remoteConfig);
    } catch (err: any) {
      error(err);
      throw new Error(err?.message || "unknown error");
    }
  },

  remoteConfigBaseUrlIsGetting: (): RemoteConfigBaseUrl | null => {
    const bearer_website_base_url = getValue(
      remoteConfig,
      "bearer_website_base_url"
    ).asString();
    window.localStorage.setItem(
      "bearerWebsiteBaseUrl",
      bearer_website_base_url
    );

    const environment_code = getValue(
      remoteConfig,
      "environment_code"
    ).asString();

    const rider_onboarding_url = getValue(
      remoteConfig,
      "rider_onboarding_url"
    ).asString();

    actionCodeSettings.url = rider_onboarding_url;

    return {
      environment_code,
      rider_onboarding_url,
      bearer_website_base_url,
    };
  },
  bearerCloudFunctionIsRunning: (): NoCloudFunctionData | null => {
    const cloudFunctionsIsRunning = getValue(
      remoteConfig,
      "bearer_cloudfunctions_is_running"
    ).asBoolean();
    if (cloudFunctionsIsRunning) return null;
    const text = getValue(
      remoteConfig,
      "bearer_cloudfunctions_status_message_text"
    ).asString();
    const buttonAction = getValue(
      remoteConfig,
      "bearer_cloudfunctions_status_message_button_action"
    ).asString();
    const url = getValue(
      remoteConfig,
      "bearer_cloudfunctions_action_url"
    ).asString();
    const supportUrl = getValue(
      remoteConfig,
      "rider_support_webpage_url"
    ).asString();
    return {
      text,
      buttonAction,
      url,
      supportUrl,
    };
  },

  bearerOnboardingIsRunning: (): NoOnboardingData | null => {
    const OnboardingIsRunning = getValue(
      remoteConfig,
      "bearer_rider_web_onboarding_is_running"
    ).asBoolean();
    if (OnboardingIsRunning) return null;
    const text = getValue(
      remoteConfig,
      "bearer_rider_web_onboarding_status_message_text"
    ).asString();
    const buttonAction = getValue(
      remoteConfig,
      "bearer_rider_web_onboarding_status_message_button_action"
    ).asString();
    const url = getValue(
      remoteConfig,
      "bearer_rider_web_onboarding_action_url"
    ).asString();
    const supportUrl = getValue(
      remoteConfig,
      "rider_support_webpage_url"
    ).asString();
    return {
      text,
      buttonAction,
      url,
      supportUrl,
    };
  },
  bearerOnboardingRemoteConfigParameters:
    (): OnboardingRemoteConfigParameters | null => {
      const rider_pp_url = getValue(remoteConfig, "rider_pp_url").asString();
      const rider_tc_url = getValue(remoteConfig, "rider_tc_url").asString();
      const stripeUrl = getValue(
        remoteConfig,
        "stripe_connect_express_base_url"
      ).asString();
      const landingpageUrl = getValue(
        remoteConfig,
        "rider_landingpage_url"
      ).asString();
      const soleTraderUrl = getValue(
        remoteConfig,
        "sole_trader_url"
      ).asString();
      const whatsappLiveSupport = getValue(
        remoteConfig,
        "whatsapp_live_support_number"
      ).asString();

      return {
        rider_pp_url,
        rider_tc_url,
        stripeUrl,
        landingpageUrl,
        soleTraderUrl,
        whatsappLiveSupport,
      };
    },
  callApiWithAppCheck: () => {
    try {
      const appCheck = initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaV3Provider(
          process.env.REACT_APP_AppCheckKey || "APP_CHECK_KEY_IS_MISSING"
        ),
        isTokenAutoRefreshEnabled: true,
      });
      return appCheck;
    } catch (err) {
      error(err);
    }
  },

  sendSignInLinkToEmail: async (email: string) => {
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", email);
    } catch (err: any) {
      error(err);
    }
  },
  isSignInWithEmailLink: async (): Promise<TSignUpResponse | undefined> => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const emailForSignIn = window.localStorage.getItem("emailForSignIn");
      if (emailForSignIn) {
        const result = await signInWithEmailLink(
          auth,
          emailForSignIn!,
          window.location.href
        );
        if (result.user) {
          const user: TSignUpResponse = {
            uid: result.user.uid,
            email: result.user.email as string,
          };
          return user;
        }
      } else if (!emailForSignIn) {
        if (auth.currentUser === null) {
          const email = window.prompt(
            "For your account security, please enter the Email Address you provided earlier for your new account."
          );
          const result = await signInWithEmailLink(
            auth,
            email!,
            window.location.href
          );
          if (result.user) {
            const user: TSignUpResponse = {
              uid: result.user.uid,
              email: result.user.email as string,
            };
            return user;
          }
        }
      }
    }
  },
  reauthenticateWithCredential: async () => {
    const credential = promptForCredentials();
    try {
      await reauthenticateWithCredential(auth.currentUser!, credential!);
    } catch (err: any) {
      error(err);
      throw new Error(err?.message || "unknown error");
    }
  },

  setPasswordInFirebase: async (password: string) => {
    try {
      await updatePassword(auth.currentUser!, password);
    } catch (err: any) {
      error(err);
      throw new Error(err?.message || "unknown error");
    }
  },
  onboardingLoginPermission:
    async (): Promise<TOnboardingLoginPermissionResponse> => {
      try {
        const t = httpsCallable<any, TOnboardingLoginPermissionResponse>(
          functions,
          "onboardingLoginPermission"
        );
        const result = await t();
        return result.data;
      } catch (err: any) {
        error(err);
        throw new Error(err?.message || "unknown error");
      }
    },

  onboardingNotifySetPassword: async (
    data: TOnboardingNotifySetPasswordRequest
  ): Promise<TOnboardingNotifySetPasswordResponse> => {
    try {
      const t = httpsCallable<
        TOnboardingNotifySetPasswordRequest,
        TOnboardingNotifySetPasswordResponse
      >(functions, "onboardingNotifySetPassword");
      const result = await t(data);
      return result.data;
    } catch (err: any) {
      error(err);
      throw new Error(err?.message || "unknown error");
    }
  },

  onboardingSendSMS: async (
    data: TOnboardingSendSMSRequest
  ): Promise<TOnboardingSendSMSResponse> => {
    try {
      const t = httpsCallable<
        TOnboardingSendSMSRequest,
        TOnboardingSendSMSResponse
      >(functions, "onboardingSendSMS");
      const result = await t(data);
      return result.data;
    } catch (err: any) {
      error(err);
      throw new Error(err?.message || "unknown error");
    }
  },

  onboardingConfirmSMS: async (
    data: TOnboardingConfirmSMSRequest
  ): Promise<TOnboardingConfirmSMSResponse> => {
    try {
      const t = httpsCallable<
        TOnboardingConfirmSMSRequest,
        TOnboardingConfirmSMSResponse
      >(functions, "onboardingConfirmSMS");
      const result = await t(data);
      return result.data;
    } catch (err: any) {
      error(err);
      throw new Error(err?.message || "unknown error");
    }
  },
  uploadProfileImage: async (uid: string, image: Blob) => {
    try {
      const imageRef = ref(storage, `riderUserAvatars/${uid}/${uid}.JPEG`);
      const r = await uploadBytesResumable(imageRef, image);
      return r.metadata.fullPath;
    } catch (err: any) {
      error(err);
      throw new Error(err?.message || "unknown error");
    }
  },

  onboardingPersonalDetails: async (
    data: TOnboardingPersonalDetailsRequest
  ): Promise<TOnboardingPersonalDetailsResponse> => {
    try {
      const t = httpsCallable<
        TOnboardingPersonalDetailsRequest,
        TOnboardingPersonalDetailsResponse
      >(functions, "onboardingPersonalDetails");
      const result = await t(data);
      return result.data;
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },
  onboardingLegalDetails: async (
    data: TOnboardingLegalDetailsRequest
  ): Promise<TOnboardingLegalDetailsResponse> => {
    try {
      const t = httpsCallable<
        TOnboardingLegalDetailsRequest,
        TOnboardingLegalDetailsResponse
      >(functions, "onboardingLegalDetails");
      const result = await t(data);
      return result.data;
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },
  uploadInsuranceFile: async (uid: string, file: Blob) => {
    const timeStamp = Math.floor(Date.now() / 1000);
    const a = file.type.split("/");
    try {
      const fileRef = ref(
        storage,
        `insurancePolicy/${uid}/temporary/${timeStamp}-IPC.${a[1]}`
      );
      const r = await uploadBytesResumable(fileRef, file);
      return r.metadata.fullPath;
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },
  uploadPublicLiabilityInsuranceFile: async (uid: string, file: Blob) => {
    const timeStamp = Math.floor(Date.now() / 1000);
    const a = file.type.split("/");
    try {
      const fileRef = ref(
        storage,
        `publicLiabilityInsurance/${uid}/temporary/${timeStamp}-IPC.${a[1]}`
      );
      const r = await uploadBytesResumable(fileRef, file);
      return r.metadata.fullPath;
    } catch (err: any) {
      error(err);
      throw new Error(err?.message || "unknown error");
    }
  },

  uploadDriversLicenseFile: async (uid: string, file: Blob) => {
    const timeStamp = Math.floor(Date.now() / 1000);
    const a = file.type.split("/");
    try {
      const fileRef = ref(
        storage,
        `idPhotoCard/${uid}/temporary/${timeStamp}.${a[1]}`
      );
      const r = await uploadBytesResumable(fileRef, file);
      return r.metadata.fullPath;
    } catch (err: any) {
      error(err);
      throw new Error(err?.message || "unknown error");
    }
  },

  uploadRegoCertificateFile: async (uid: string, file: Blob) => {
    const timeStamp = Math.floor(Date.now() / 1000);
    const a = file.type.split("/");
    try {
      const fileRef = ref(
        storage,
        `insurancePolicy/${uid}/temporary/${timeStamp}-RC.${a[1]}`
      );
      const r = await uploadBytesResumable(fileRef, file);
      return r.metadata.fullPath;
    } catch (err: any) {
      error(err);
      throw new Error(err?.message || "unknown error");
    }
  },

  onboardingAddMotorbike: async (
    data: TOnboardingAddMotorbikeRequest
  ): Promise<TOnboardingAddMotorbikeResponse> => {
    try {
      const t = httpsCallable<
        TOnboardingAddMotorbikeRequest,
        TOnboardingAddMotorbikeResponse
      >(functions, "onboardingAddMotorbike");
      const result = await t(data);
      return result.data;
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },
  onboardingRemoveMotorbike: async (
    data: TOnboardingRemoveMotorbikeRequest
  ): Promise<TOnboardingRemoveMotorbikeResponse> => {
    try {
      const t = httpsCallable<
        TOnboardingRemoveMotorbikeRequest,
        TOnboardingRemoveMotorbikeResponse
      >(functions, "onboardingRemoveMotorbike");
      const result = await t(data);
      return result.data;
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },

  onboardingEditMotorbike: async (
    data: TOnboardingEditMotorbikeRequest
  ): Promise<TOnboardingEditMotorbikeResponse> => {
    try {
      const t = httpsCallable<
        TOnboardingEditMotorbikeRequest,
        TOnboardingEditMotorbikeResponse
      >(functions, "onboardingEditMotorbike");
      const result = await t(data);
      return result.data;
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },
  onboardingModesOfTransport: async (
    data: TOnboardingModesOfTransportRequest
  ): Promise<TOnboardingModesOfTransportResponse> => {
    try {
      const t = httpsCallable<
        TOnboardingModesOfTransportRequest,
        TOnboardingModesOfTransportResponse
      >(functions, "onboardingModesOfTransport");
      const result = await t(data);
      return result.data;
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },
  onboardingGetStripeExpressLink:
    async (): Promise<TOnboardingGetStripeExpressLinkResponse> => {
      try {
        const t = httpsCallable<void, TOnboardingGetStripeExpressLinkResponse>(
          functions,
          "onboardingGetStripeExpressLink"
        );
        const result = await t();
        return result.data;
      } catch (err: any) {
        throw new Error(err?.message || "unknown error");
      }
    },
  onboardingStripe: async (): Promise<TOnboardingStripeResponse> => {
    try {
      const t = httpsCallable<void, TOnboardingStripeResponse>(
        functions,
        "onboardingStripe"
      );
      const result = await t();
      return result.data;
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },
  onboardingGetNccLink: async (): Promise<TOnboardingGetNccLinkResponse> => {
    try {
      const t = httpsCallable<void, TOnboardingGetNccLinkResponse>(
        functions,
        "onboardingGetNccLink"
      );
      const result = await t();
      return result.data;
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },

  onboardingNccNextButton:
    async (): Promise<TOnboardingNccNextButtonResponse> => {
      try {
        const t = httpsCallable<void, TOnboardingNccNextButtonResponse>(
          functions,
          "onboardingNccNextButton"
        );
        const result = await t();
        return result.data;
      } catch (err: any) {
        throw new Error(err?.message || "unknown error");
      }
    },

  onboardingDigitalSignature: async (
    data: TOnboardingDigitalSignatureRequest
  ): Promise<TOnboardingDigitalSignatureResponse> => {
    try {
      const t = httpsCallable<
        TOnboardingDigitalSignatureRequest,
        TOnboardingDigitalSignatureResponse
      >(functions, "onboardingDigitalSignature");
      const result = await t(data);
      return result.data;
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },

  login: async (
    email: string,
    password: string
  ): Promise<TLoginResponse | undefined> => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      if (result.user) {
        const user: TLoginResponse = {
          uid: result.user.uid,
        };
        return user;
      } else {
        // to do
        error(
          "please complete  the login function in bearer_api.tsx, there is no user in result"
        );
      }
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },
  logout: async () => {
    try {
      await signOut(auth);
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },
  resetPassword: async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err: any) {
      throw new Error(err?.message || "unknown error");
    }
  },
  isLoaded: (): boolean => {
    return !!auth.currentUser;
  },
  onLoad: (handleLoad: () => void): void => {
    onAuthStateChanged(auth, () => {
      handleLoad();
    });
  },
};

export type TBearerApi = typeof BearerApi;

function promptForCredentials() {
  if (auth.currentUser?.email) {
    BearerApi.sendSignInLinkToEmail(auth.currentUser?.email);
  }
}
