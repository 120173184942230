import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StepsEnum, useAppState } from "../../states/app-state";
import MobileLinearStepper from "../stepper/MobileLinearStepper";
import ContactDetails from "./contactDetails/ContactDetails";
import DigitalSignature from "./DigitalSignature";
import IdVerification from "./IdVerification";
import LegalDetails from "./legalDetails/LegalDetails";
import ModesOFTransport from "./ModesOfTransport";
import PersonalDetails from "./personalDetails/PersonalDetails";
import StripeOnboarding from "./StripeOnboarding";
import TermsAndConditions from "./TermsAndConditions";

const RightSide: React.FC = observer(() => {
    const appState = useAppState();
    const { step } = appState.registration;
    return (
        <div style={{
            backgroundColor: '#f4f6f8', width: '100%', overflowY: 'auto', overflowX: "hidden"
        }}>
            <div style={{ width: '80%', margin: '42px auto' }}>
                <Box sx={{ display: { xs: 'block', sm: 'none' }, marginTop: '50px' }}>
                    <MobileLinearStepper />
                </Box>
                {step === StepsEnum.TermsAndConditionsAndPrivacyPolicy && <TermsAndConditions />}
                {step === StepsEnum.ContactDetails && <ContactDetails />}
                {step === StepsEnum.PersonalDetails && <PersonalDetails />}
                {step === StepsEnum.LegalDetails && <LegalDetails />}
                {step === StepsEnum.ModesOfTransport && <ModesOFTransport />}
                {step === StepsEnum.StripeOnboarding && <StripeOnboarding />}
                {step === StepsEnum.IDVerification && <IdVerification />}
                {step === StepsEnum.DigitalSignature && <DigitalSignature />}
            </div>
        </div>
    )
})
export default RightSide;