import React from "react";
import { CircularProgress, Container } from "@mui/material";
import { observer } from "mobx-react-lite";
import { AppStateProvider, useAppState } from "../states/app-state";
import CheckServerRunningStates from "./CheckServerRunningStates";
import ErrorMessages from "./ErrorMessages";
import RegistrationSteps from "./RegistrationSteps";
import SuccessMessages from "./SuccessMessages";
import MainPage from "./MainPage";
import "react-toastify/dist/ReactToastify.css";
import GoogleTagManager from "../components/tags/googleTagManager";
import FacePixel from "../components/tags/facePixel";
import HotjarTracking from "../components/tags/hotjar";

const Scaffold: React.FC = observer(() => {
  const appState = useAppState();
  const environment = appState.remoteConfigBaseUrl?.environment_code
  const isActiveTabExist = window.localStorage.getItem("activeTab")?.length === 1

  const renderContent = (appState: any) => {
    switch (true) {
      case appState.registration.step && appState.login.loggedin:
        return <RegistrationSteps />;
      case appState.registration.step && !appState.login.loggedin:
        return <RegistrationSteps />;
      case !appState.registration.step && !appState.login.loggedin && window.location.pathname === '/new' && !isActiveTabExist:
        appState.registration.setTermsAndConditionStep();
        return <RegistrationSteps />;
      case !appState.registration.step && !appState.login.loggedin && isActiveTabExist:
        return <MainPage />;
      default:
        return <MainPage />;
    }
  };

  return (
    <>
      {environment === "PRODUCTION" &&
        <>
          <GoogleTagManager />
          <FacePixel />
          <HotjarTracking />
        </>
      }
      <Container maxWidth={false} style={{ padding: 0, margin: "0 auto" }}>
        {!appState.serverSync && (
          <CircularProgress sx={{ display: "block", margin: "100px auto" }} />
        )}
        {appState.serverSync && (
          <>
            {appState.serverChecked && (
              <>
                {renderContent(appState)}
                <ErrorMessages />
                <SuccessMessages />
              </>
            )}
            {!appState.serverChecked && <CheckServerRunningStates />}
          </>
        )}
      </Container>
    </>
  );
});
export default Scaffold;