import { Grid } from "@mui/material";
import LoginPage from "./login/LoginPage";
import SignUpPage from "./signUp/SignUpPage";
import become from "../assets/Becoming-Courier.png";
import speedy from "../assets/Speedy-Bearer.png";
import logo from "../assets/bearer-logo.svg";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React from "react";
import notice from "../assets/requirements-notice.svg";
import wallet from "../assets/wallet.svg";
import instagram from "../assets/instagram.svg";
import twitter from "../assets/twitter.svg";
import facebook from "../assets/facebook.svg";
import linkedin from "../assets/linkedin.svg";
import { useAppState } from "../states/app-state";
const MainPage: React.FC = () => {
  const appState = useAppState();
  const [value, setValue] = React.useState(window.localStorage.getItem("activeTab") === "1" ? "1" : "2");
  const envBaseUrl =
    appState.remoteConfigBaseUrl !== null
      ? appState.remoteConfigBaseUrl.bearer_website_base_url
      : "";
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    window.localStorage.removeItem("activeTab")
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={5.5} lg={5} xl={4}>
          <div className="left-wrapper">
            <div className="login-info-wrapper">
              <img src={logo} alt="logo" />
              <h1>Become a Bearer to start your impressive journey.</h1>
              <h6>
                Increase your earnings by filling your spare time in between
                your delivery jobs!
                <br />
                Bearers can complete jobs by riding a motorbike, cycling or even
                on-foot during their daily exercise.
              </h6>

              <div className="news-info">
                <ul>
                  <li>
                    <a
                      href={envBaseUrl + "/Blog/What-is-Bearer"}
                      target="_blank"
                    >
                      <div className="thumbnail">
                        <img src={become} alt="bearer" />
                        <h2>What is Bearer?</h2>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        envBaseUrl +
                        "/Blog/Discover-the-hidden-benefits-of-becoming-a-Bearer"
                      }
                      target="_blank"
                    >
                      <div className="thumbnail">
                        <img src={speedy} alt="bearer" />
                        <h2>
                          Discover the hidden benefits of becoming a Bearer
                        </h2>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="support">
                Need to share something with us?
                <a href={envBaseUrl + "/Contact"} target="_blank">
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </Grid>
        <div className="login-page-social-media">
          <ul>
            <li>
              <a href="https://www.instagram.com/Bearer.AU" target="_blank">
                <img src={instagram} alt="instagram" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Bearer.AU" target="_blank">
                <img src={facebook} alt="facebook" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/Bearer_AU" target="_blank">
                <img src={twitter} alt="twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/bearer-au"
                target="_blank"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
            </li>
          </ul>
        </div>
        <Grid item xs={12} md={6.5} lg={7} xl={8}>
          <div className="main-login-right">
            <div className="menu">
              <ul>
                <li>
                  <a href={envBaseUrl} target="_blank">
                    Bearer Home
                  </a>
                </li>
                .
                <li>
                  <a href={envBaseUrl + "/About"} target="_blank">
                    About Us
                  </a>
                </li>
                .
                <li>
                  <a href={envBaseUrl + "/FAQs"} target="_blank">
                    FAQs
                  </a>
                </li>
              </ul>
            </div>
            <div className="title">Join in just a few easy steps</div>
            <div className="sub-title">Registration Form</div>
            <div className="login-tab-bar">
              <Box>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Resume an Application" value="1" />
                      <Tab label="New Registeration" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <LoginPage />
                  </TabPanel>
                  <TabPanel value="2">
                    <SignUpPage />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </Grid>
        <section className="requirement">
          <h2>Ready to join? Make sure you tick all the boxes</h2>
          <h6>Requirements</h6>

          <Grid container className="custom_row" spacing={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className="thumbnail">
                <div className="wrapper">
                  <img src={notice} alt="rider" />
                  <h5>You are 18 years or older</h5>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className="thumbnail">
                <div className="wrapper">
                  <img src={notice} alt="rider" />
                  <h5>You pass a criminal background check</h5>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className="thumbnail">
                <div className="wrapper">
                  <img src={notice} alt="rider" />
                  <h5>You have the right to work in Australia</h5>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className="thumbnail">
                <div className="wrapper">
                  <img src={notice} alt="rider" />
                  <h5>You are a registered sole trader with an active ABN</h5>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className="thumbnail">
                <div className="wrapper">
                  <img src={notice} alt="rider" />
                  <h5>You are sufficiently physically fit</h5>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className="thumbnail">
                <div className="wrapper">
                  <img src={notice} alt="rider" />
                  <h5>You have a mobile phone and data plan</h5>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="makeMoney">
            <span className="downloadAppWrapper">
              <img src={wallet} alt="download-app" />
              <span className="title">
                Register today and Start making money
              </span>
            </span>
          </div>
        </section>
      </Grid>
    </>
  );
};
export default MainPage;
